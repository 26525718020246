import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Representativeness`}</h1>
    <p>{`The degree to which a given `}<a parentName="p" {...{
        "href": "/comment",
        "title": "comment"
      }}>{`comment`}</a>{` differentiates one `}<a parentName="p" {...{
        "href": "/group",
        "title": "group"
      }}>{`group`}</a>{` from another`}</p>
    <p>{`See: `}<a parentName="p" {...{
        "href": "/Representative-Comments",
        "title": "Representative Comments"
      }}>{`Representative Comments`}</a>{``}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      